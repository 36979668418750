import { AxiosError } from 'axios'

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore()
  const creatorStore = useCreatorStore()

  let creatorNcnm = ''

  try {
    if (!creatorStore.profile) {
      const { userNcnm } = await creatorStore.fetchProfileLink({
        creatorLink: String(to.params.id).replace('@', ''),
      })
      creatorNcnm = userNcnm
    } else {
      creatorNcnm = creatorStore.profile.userNcnm
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      throw showError({
        statusCode: err.response?.status,
        statusMessage: err.message,
      })
    } else {
      throw showError({
        statusCode: 500,
        statusMessage: 'creator page access fail',
      })
    }
  }

  if (process.client) {
    const accessAble =
      userStore.user && userStore.user?.userNcnm === creatorNcnm

    if (!accessAble) {
      throw showError({
        statusCode: 403,
        statusMessage: 'creator page access require login',
      })
    }
  }
})
